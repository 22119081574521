import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { CardHeader, Flex, Heading } from "@chakra-ui/react";
import { DeleteJobService, EditJobService } from "../../../../core/Services";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../auth/core/AuthContext";

import { Link } from "react-router-dom";
export default function InProgress() {
  const [jobs, setJobs] = useState([]);
  const { userData } = useAuth();
  const [employeeList, setEmployeeList] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState([]);
  const dt = useRef(null);
  const [newDep, setNewDep] = useState("");
  const [jobsNo, setJobsNo] = useState("");
  const [newCus, setNewCus] = useState("");
  const [newState, setNewState] = useState("");
  const [newDesc, setNewDesc] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newSub, setNewSub] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [assignedEmp, setAssignedEmp] = useState(false);
  const [search, setSearch] = useState("");
  const jobData = useSelector((state) => state.JobReduce.value);
  const userDatas = useSelector((state) => state.UserReduce.value);
  const departmentData = useSelector((state) => state.DepartmentReduce.value);
  const customerData = useSelector((state) => state.CustomerReduce.value);
  useEffect(() => {
    setJobs(jobData);
    setEmployeeList(userDatas);
    setCustomers(customerData);
    setDepartments(departmentData);
  }, [jobData, userDatas, customerData, departmentData]);

  const saveChanges = async (id) => {
    let formattedDate = "";
    if (newDate !== "") {
      formattedDate = formatDate(newDate);
    }

    let requestBody = {
      id,
      newCus,
      newDate: formattedDate,
      newDep,
      newDesc,
      newState,
      newSub,
      assignedEmp,
    };

    await EditJobService(requestBody);

    setEditModal(false);
  };
  const hideDialog = () => {
    setEditModal(false);
  };

  const searchEmpInTask = async (value) => {
    if (Array.isArray(jobData) && jobData.length > 0) {
      const matchingTasks = jobData.filter((task) => {
        if (typeof task.designated_person === "string") {
          const designatedPersons = task.designated_person
            ?.split(",")
            ?.map((person) => person.trim());
          return designatedPersons.includes(value.toString());
        }
        return false;
      });
      setJobs(matchingTasks);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  const workingBodyTemplate = (rowData) => {
    const getStatusLabel = (working_condition) => {
      switch (working_condition) {
        case "0":
          return "Yüksek";

        case "1":
          return "Normal";

        case "2":
          return "Kritik";

        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.working_condition);

    return <Tag value={statusLabel} severity={getSeverity(rowData)}></Tag>;
  };

  const getSeverity = (tasks) => {
    switch (tasks.working_condition) {
      case "0":
        return "warning";

      case "1":
        return "success";

      case "2":
        return "danger";

      default:
        return null;
    }
  };
  const statusType = [
    { label: "Normal", value: "1" },
    { label: "Yüksek", value: "0" },
    { label: "Kritik", value: "2" },
  ];

  const accessors = (rowData) => {
    const edit = editModal[rowData.id] || false;
    const deletes = deleteModal[rowData.id] || false;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          {userData && (userData.type === "1" || userData.is_auth === "1") && (
            <Button
              severity="warning"
              onClick={() => {
                setSelectedJob(rowData);
                setEditModal((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
              }}
              icon="pi pi-pencil"
            />
          )}
        </div>
        <div style={{ color: "#ae445a" }}>
          {userData?.type === "1" && (
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setDeleteModal((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setNewDesc(rowData?.desc);
                setJobsNo(rowData?.jobs_no);
                if (rowData?.customer_id) {
                  const cust = customers.find(
                    (dept) => dept.id.toString() === rowData.customer_id
                  );
                  if (cust) {
                    const firstTwoWords =
                      cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                    setNewCus(firstTwoWords);
                  } else {
                    setNewCus("Bilinmeyen");
                  }
                }

                if (rowData?.department) {
                  const department = departments.find(
                    (dept) => dept.id.toString() === rowData.department
                  );
                  if (department) {
                    setNewDep(department.department);
                  }
                }
                setSelectedJob(rowData);
              }}
              severity="danger"
            />
          )}
        </div>

        <div style={{ color: "#00d9ff" }}>
          <Link to={`/jobs/details?id=${rowData.id}`}>
            <Button icon="pi pi-arrow-right-arrow-left" severity="info" />
          </Link>
        </div>

        <Dialog
          header="İşi silmek istediğinize emin misiniz?"
          visible={deletes}
          style={{ width: "50vw" }}
          onHide={() => {
            setDeleteModal(false);
            setNewDesc("");
            setNewDep("");
            setJobsNo("");
            setNewCus("");
          }}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={() => {
                  setDeleteModal(false);
                  setNewDesc("");
                  setNewDep("");
                  setJobsNo("");
                  setNewCus("");
                }}
                className="btn-excel-two"
              />
              <Button
                label="İşi Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteJobService(selectedJob.id);
                  setNewDesc("");
                  setNewDep("");
                  setJobsNo("");
                  setNewCus("");
                  setDeleteModal(false);
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Müşteri Numarası: {jobsNo}
              </label>
            </div>
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Müşteri Adı: {newCus}
              </label>
            </div>
            <div className="field">
              <label htmlFor="description" className="font-bold">
                Açıklama : {newDesc}
              </label>
            </div>
            <div className="field">
              <label className="mb-3 font-bold">Departman: {newDep}</label>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={edit}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="İşi Düzenle"
          onHide={hideDialog}
          modal
        >
          <div className="confirmation-content">
            <div className=" jc-space-around">
              {/* Sol Sütun */}
              <div className="col-6 " style={{ display: "inline-block" }}>
                <Dropdown
                  filter
                  options={
                    customers && customers.length > 0
                      ? customers?.map((dep) => ({
                          label: dep.title,
                          value: dep.id,
                        }))
                      : []
                  }
                  value={newCus}
                  placeholder={
                    selectedJob.customer_id
                      ? (
                          customers.find(
                            (customer) =>
                              customer.id.toString() === selectedJob.customer_id
                          ) || {}
                        ).title || "Bilinmeyen"
                      : "Bilinmeyen"
                  }
                  onChange={(e) => {
                    setNewCus(e.target.value);
                  }}
                  style={{
                    marginBottom: "1rem",
                    border: "1px solid gray",
                    width: "100%",
                  }}
                />

                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder="Konu"
                  onChange={(e) => setNewSub(e.target.value)}
                  defaultValue={selectedJob.subject}
                />

                <Dropdown
                  filter
                  value={newDep}
                  options={
                    Array.isArray(departments) && departments.length > 0
                      ? departments?.map((dep) => ({
                          label: dep.department,
                          value: dep.id,
                        }))
                      : []
                  }
                  placeholder={
                    selectedJob.department
                      ? (
                          departments.find(
                            (customer) =>
                              customer.id.toString() === selectedJob.department
                          ) || {}
                        ).department || "Bilinmeyen"
                      : "Bilinmeyen"
                  }
                  onChange={(e) => {
                    setNewDep(e.target.value);
                  }}
                  style={{
                    border: "1px solid gray",
                    marginTop: "1rem",
                    width: "100%",
                  }}
                />
                <MultiSelect
                  filter
                  value={assignedEmp}
                  options={
                    Array.isArray(employeeList) && employeeList.length > 0
                      ? employeeList?.map((employ) => ({
                          label: employ.name,
                          value: employ.id,
                        }))
                      : []
                  }
                  placeholder="Görevliler"
                  className="w-full "
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                  onChange={(e) => setAssignedEmp(e.target.value)}
                />
              </div>
              {/* Sağ Sütun */}
              <div className="col-6" style={{ display: "inline-block" }}>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder="Açıklama"
                  onChange={(e) => setNewDesc(e.target.value)}
                  defaultValue={selectedJob.desc}
                  style={{ marginBottom: "1rem" }}
                />
                <Dropdown
                  filter
                  options={statusType}
                  placeholder={
                    selectedJob.status
                      ? (
                          statusType.find(
                            (customer) =>
                              Number(customer.value) ===
                              Number(selectedJob.status)
                          ) || {}
                        ).label || "Bilinmeyen"
                      : "Bilinmeyen"
                  }
                  value={newState}
                  className="w-full"
                  onChange={(e) => setNewState(e.value)}
                  style={{
                    border: "1px solid gray",
                    marginBottom: "1rem",
                  }}
                />

                <Calendar
                  value={newDate}
                  dateFormat="dd/mm/yy"
                  onChange={(e) => setNewDate(e.value)}
                  placeholder={formatDate(selectedJob.finish_date)}
                  locale="tr"
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                    padding: "0.5rem",
                    marginBottom: "1rem",
                    borderRadius: "6px 6px 6px 6px",
                  }}
                />
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    label="Kaydet"
                    onClick={() => saveChanges(selectedJob.id)}
                    className="btn-excel-two"
                  />
                </div>
              </div>{" "}
            </div>{" "}
          </div>
        </Dialog>
      </div>
    );
  };
  return (
    <div>
      <Flex justifyContent="center" alignItems="center">
        <div
          className="card"
          style={{
            overflow: "auto",
            boxSizing: "border-box",
            width: "100%",
            height: "100%%",
            position: "relative",
          }}
        >
          <Card style={{ textAlign: "left" }}>
            <CardHeader>
              <Flex justifyContent="space-between" alignItems="center">
                <Heading size="md" style={{ color: "black" }}>
                  İşlemde Olan İşler
                </Heading>
                <div style={{ display: "flex" }}>
                  <Dropdown
                    filter
                    value={search}
                    options={
                      Array.isArray(employeeList) && employeeList.length > 0
                        ? employeeList?.map((employ) => ({
                            label: employ.name,
                            value: employ.id,
                          }))
                        : []
                    }
                    placeholder="Çalışana Göre Filtrele"
                    className="w-full "
                    style={{
                      border: "1px solid gray",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      searchEmpInTask(e.target.value);
                    }}
                  />
                </div>
              </Flex>
            </CardHeader>
            <DataTable
              stripedRows
              ref={dt}
              size="small"
              value={jobs && jobs.filter((jobs) => Number(jobs.jobs_id) === 1)}
              dataKey="id"
              paginator
              scrollable
              tableStyle={{ maxWidth: "85vw" }}
              rows={15}
              rowsPerPageOptions={[15, 30, 60, 100]}
              scrollHeight="60vh"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} işten {first} - {last} arası gösteriliyor"
            >
              <Column
                style={{ width: "8rem" }}
                body={accessors}
                exportable={false}
                header="İşlemler"
              ></Column>
              <Column field="jobs_no" sortable header="İş Emri No"></Column>
              <Column
                field="title"
                header="Müşteri"
                body={(rowData) => {
                  const cust = customers.find(
                    (dept) => dept.id.toString() === rowData.customer_id
                  );
                  if (cust) {
                    const firstTwoWords =
                      cust.title &&
                      cust.title?.split(" ").slice(0, 2)?.join(" ");
                    return firstTwoWords;
                  } else {
                    return "Bilinmeyen";
                  }
                }}
              ></Column>
              <Column
                field="subject"
                style={{ maxWidth: "8rem" }}
                header="Konu"
              ></Column>
              <Column
                field="desc"
                header="Açıklama"
                style={{ maxWidth: "20rem" }}
              ></Column>
              <Column
                field="department"
                sortable
                header="Departman"
                body={(rowData) => {
                  const department = departments.find(
                    (dept) => dept.id.toString() === rowData.department
                  );
                  return department
                    ? department.department
                    : "Unknown Department";
                }}
                style={{ minWidth: "6rem" }}
              ></Column>
              <Column
                header="Öncelik"
                body={workingBodyTemplate}
                style={{ minWidth: "4rem" }}
                filterMatchMode="equals"
                filterPlaceholder="Filter by Status"
              ></Column>
              <Column
                field="designated_person"
                sortable
                header="Atanan Kişi"
                body={(rowData) => {
                  const designatedPersons =
                    rowData?.designated_person?.split(","); // Virgüllerle ayrılmış kimlikleri diziye ayır

                  const assignedUsers = designatedPersons?.map(
                    (designatedPerson) => {
                      const user = employeeList.find(
                        (usr) => usr.id.toString() === designatedPerson.trim()
                      );

                      return user ? user.name : "Bilinmeyen"; // Kullanıcı adını bul veya "Unknown User" döndür
                    }
                  );

                  return assignedUsers?.join(", "); // Virgülle ayrılmış kullanıcı adlarını birleştir ve sütuna yazdır
                }}
                style={{ maxWidth: "7rem" }}
              ></Column>

              <Column
                field="created_at"
                sortable
                header="Oluşturulma Tarihi"
                body={(rowData) => formatDate(rowData.created_at)}
                style={{ minWidth: "6rem" }}
              ></Column>
            </DataTable>
          </Card>
        </div>
      </Flex>
    </div>
  );
}

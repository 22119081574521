import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Tag } from "primereact/tag";
import { useSelector } from "react-redux";

export default function Completed() {
  const [addModal] = useState(false);

  const [tasks, setTasks] = useState([]);
  const [customers, setCustomers] = useState([]);
  const taskData = useSelector((state) => state.TaskReduce.value);
  const userData = useSelector((state) => state.UserReduce.value);
  const customerData = useSelector((state) => state.CustomerReduce.value);

  const statusBodyTemplate = (rowData) => {
    const getStatusLabel = (status) => {
      switch (status) {
        case "0":
          return "Boşta";
        case "1":
          return "İşlemde";
        case "2":
          return "Tamamlamdı";
        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.status);

    return <Tag value={statusLabel} severity={getStatus(rowData)}></Tag>;
  };

  const getStatus = (tasks) => {
    switch (tasks.status) {
      case "0":
        return "danger";
      case "1":
        return "warning";
      case "2":
        return "success";
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTasks(taskData);

        setCustomers(customerData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [addModal, taskData, userData, customerData]);
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Tamamlanan Destek</Heading>
            </Flex>
          </CardHeader>

          <DataTable
            stripedRows
            size="small"
            value={
              tasks && tasks.filter((tasks) => Number(tasks.tasks_id) === 4)
            }
            dataKey="id"
            tableStyle={{ maxWidth: "85vw" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} destekten {first} - {last} arası gösteriliyor"
          >
            <Column
              field="name"
              header="Müşteri"
              body={(rowData) => {
                const cust = customers.find(
                  (dept) => dept.id.toString() === rowData.name
                );
                if (cust) {
                  // Müşteri başlığını 30 karaktere kısalt

                  const shortenedTitle =
                    cust.title && cust.title.length > 25
                      ? cust.title.substring(0, 25) + "..."
                      : cust.title;
                  return shortenedTitle;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>
            <Column field="department" header="Konu"></Column>
            <Column
              field="desc"
              style={{ maxWidth: "20rem" }}
              header="Açıklama"
            ></Column>
            <Column
              field="status"
              header="Durum"
              body={statusBodyTemplate}
              style={{ minWidth: "6rem" }}
            ></Column>
            <Column
              field="finisher"
              header="Tamamlayan"
              style={{ minWidth: "6rem" }}
            ></Column>
            <Column
              field="created_at"
              header="Oluşturulma Tarihi"
              body={(rowData) => formatDate(rowData.created_at)}
              style={{ minWidth: "6rem" }}
            ></Column>
          </DataTable>
        </Card>
      </div>
    </Flex>
  );
}
